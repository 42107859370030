import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Home: Page = () => <></>;

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});
  

export default Home;
